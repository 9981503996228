import React from "react"
import { Link } from "gatsby"

const NewlyDiagnosedSubmenu = () => {
  return (
    <>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Coping with a Diagnosis" activeClassName="isActive" to="/diagnosis/">Coping With a Neuroblastoma Diagnosis <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Understanding Treatment Options" activeClassName="isActive" partiallyActive={true} to="/diagnosis/understanding-treatment/">Understanding the Treatment Options <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Preparing for Treatment" activeClassName="isActive" partiallyActive={true} to="/diagnosis/treatment-options/">Preparing for Treatment <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Getting to Know Your Treatment Team" activeClassName="isActive" partiallyActive={true} to="/diagnosis/healthcare-team/">Getting to Know Your Treatment Team <span className="np-arrow">&rsaquo;</span></Link></li>
    </>
  )
}

export default NewlyDiagnosedSubmenu
